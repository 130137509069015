import { TypedDocumentNode, gql } from "@apollo/client";
import { tag, typeOrStatus } from "../types/common";
import {
  TASK_TYPE_FIELDS,
  TASK_STATUS_FIELDS,
  TASK_CORE_FIELDS,
  TASK_TAG_FIELDS,
} from "../fragments/task";
import { task } from "../types/task";
import { product } from "../types/product";
import { company } from "../types/company";
import { PRODUCT_FIELDS } from "../fragments/product";
import { COMPANY_FIELDS, COMPANY_TYPE_FIELDS } from "../fragments/company";
import { AuthPermission } from "../types/auth";

export const STREAM_TASKS: TypedDocumentNode<{
  tasks_stream: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  subscription STREAM_TASKS(
    $where: tasks_bool_exp
    $cursor: [tasks_stream_cursor_input]!
  ) {
    tasks_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...TaskCoreFields
    }
  }
`;

export const UPDATE_TASK_BY_ID: TypedDocumentNode<{
  update_tasks_by_pk: task;
}> = gql`
  ${TASK_CORE_FIELDS}
  mutation UPDATE_TASK_BY_ID($id: Int!, $set: tasks_set_input!) {
    update_tasks_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...TaskCoreFields
    }
  }
`;

export const ADD_TASK = gql`
  ${TASK_CORE_FIELDS}
  mutation ADD_TASK($object: tasks_insert_input!) {
    insert_tasks_one(object: $object) {
      ...TaskCoreFields
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DELETE_TASK($id: Int!) {
    delete_tasks_by_pk(id: $id) {
      id
    }
  }
`;

export const SEARCH_TASK: TypedDocumentNode<{
  tasks: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  query SEARCH_TASK($where: tasks_bool_exp!) {
    tasks(where: $where, limit: 20) {
      ...TaskCoreFields
    }
  }
`;

export const GET_TASKS_CONDITIONAL: TypedDocumentNode<{
  tasks: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  query GET_TASKS($where: tasks_bool_exp!) {
    tasks(where: $where) {
      ...TaskCoreFields
    }
  }
`;

export const GET_TASKS_MAIN: TypedDocumentNode<{
  tasks: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  query GET_TASKS_MAIN(
    $where: tasks_bool_exp!
    $limit: Int
    $order_by: [tasks_order_by!]
    $offset: Int
  ) {
    tasks(where: $where, limit: $limit, order_by: $order_by, offset: $offset) {
      ...TaskCoreFields
    }
  }
`;

export const GET_TASKS_TOTAL_MAIN: TypedDocumentNode<{
  tasks_aggregate: { aggregate: { count: number } };
}> = gql`
  query GET_TASKS_TOTAL_MAIN($where: tasks_bool_exp!) {
    tasks_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const STREAM_TASKS_MAIN: TypedDocumentNode<{
  tasks_stream: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  subscription STREAM_TASKS_MAIN(
    $where: tasks_bool_exp
    $cursor: [tasks_stream_cursor_input]!
  ) {
    tasks_stream(cursor: $cursor, where: $where, batch_size: 10) {
      ...TaskCoreFields
    }
  }
`;

export const READ_TASK_BY_ID: TypedDocumentNode<{
  update_tasks_by_pk: task;
}> = gql`
  ${TASK_CORE_FIELDS}
  mutation READ_TASK_BY_ID($id: Int!, $userId: jsonb!) {
    update_tasks_by_pk(pk_columns: { id: $id }, _append: { reads: $userId }) {
      ...TaskCoreFields
    }
  }
`;

export const READ_TASKS: TypedDocumentNode<{
  update_tasks: task[];
}> = gql`
  ${TASK_CORE_FIELDS}
  mutation UPDATE_TASKS($ids: [Int!]!, $userId: jsonb!) {
    update_tasks(where: { id: { _in: $ids } }, _append: { reads: $userId }) {
      returning {
        ...TaskCoreFields
      }
    }
  }
`;

export const GET_TASK_RELATED_CONFIGS: TypedDocumentNode<{
  products: Omit<
    product,
    "photo" | "created_at" | "updated_at" | "description"
  >[];
  taskTags: tag[];
  companies: Omit<
    company,
    "sources" | "users" | "updated_at" | "created_at" | "description"
  >[];
  users: {
    id: number;
    name: string;
    companyId?: number;
    auth: {
      id: number;
      name: string;
      permissions: AuthPermission[];
    };
  }[];
}> = gql`
  ${PRODUCT_FIELDS}
  ${TASK_TAG_FIELDS}
  ${COMPANY_FIELDS}
  ${COMPANY_TYPE_FIELDS}
  query GET_TASK_RELATED_CONFIGS {
    products {
      id
      brand
      name
      price
      serial
    }
    taskTags {
      ...TaskTagFields
    }
    users(where: { status: { considerActive: { _eq: true } } }) {
      id
      name
      companyId
      auth {
        id
        name
        permissions
      }
    }
    companies {
      id
      isQuezone
      name
      email
      phone
      address
      registration
      subCompanies
      typeId
      type {
        ...CompanyTypeFields
      }
      customerTypeIds
      customerStatusIds
      invLocations
      noUsers
      active
    }
  }
`;

export const SEARCH_TASK_TAG: TypedDocumentNode<{
  taskTags: tag[];
}> = gql`
  query SEARCH_TASK_TAG($Str: String!) {
    taskTags(where: { name: { _ilike: $Str } }) {
      id
      name
    }
  }
`;

export const GET_TASK_TAGS_BY_IDS: TypedDocumentNode<{
  taskTags: tag[];
}> = gql`
  query GET_TASK_TAGS_BY_IDS($ids: [Int!]!) {
    taskTags(where: { id: { _in: $ids } }) {
      id
      name
    }
  }
`;

export const GET_TASK_TAGS: TypedDocumentNode<{
  taskTags: tag[];
}> = gql`
  query GET_TASK_TAGS {
    taskTags {
      id
      name
    }
  }
`;

export const ADD_TASK_TAG = gql`
  mutation ADD_TASK_TAG($object: taskTags_insert_input!) {
    insert_taskTags_one(object: $object) {
      id
      name
    }
  }
`;

export const ADD_TASK_TAG_MANY = gql`
  mutation ADD_TASK_TAG_MANY($objects: [taskTags_insert_input!]!) {
    insert_taskTags(objects: $objects) {
      returning {
        id
        name
      }
    }
  }
`;

export const GET_TASK_BY_PK: TypedDocumentNode<{
  tasks_by_pk: task;
}> = gql`
  ${TASK_CORE_FIELDS}
  query GET_TASK_BY_PK($id: Int!) {
    tasks_by_pk(id: $id) {
      ...TaskCoreFields
    }
  }
`;

export const GET_TASKS_M: TypedDocumentNode<{
  tasks: {
    id: number;
    uuid: string;
    customerId: number;
  }[];
}> = gql`
  query GET_TASKS_M {
    tasks {
      id
      uuid
      customerId
    }
  }
`;

export const DELETE_TASK_TAG = gql`
  mutation DELETE_TASK_TAG($id: Int!) {
    delete_taskTags_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_TASK_TAG_BY_ID: TypedDocumentNode<{
  update_taskTags_by_pk: typeOrStatus;
}> = gql`
  mutation UPDATE_TASK_TAG_BY_ID($id: Int!, $set: taskTags_set_input!) {
    update_taskTags_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      name
    }
  }
`;

export const UPDATE_TASK_MANY = gql`
  ${TASK_CORE_FIELDS}
  mutation UPDATE_TASK_MANY($updates: [tasks_updates!]!) {
    update_tasks_many(updates: $updates) {
      returning {
        ...TaskCoreFields
      }
    }
  }
`;

export const GET_TASK_TYPES: TypedDocumentNode<{
  taskTypes: typeOrStatus[];
}> = gql`
  query GetTaskTypes {
    taskTypes {
      ...TaskTypeFields
    }
  }
  ${TASK_TYPE_FIELDS}
`;

export const GET_TASK_STATUSES: TypedDocumentNode<{
  taskStatuses: typeOrStatus[];
}> = gql`
  query GetTaskStatuses {
    taskStatuses {
      ...TaskStatusFields
    }
  }
  ${TASK_STATUS_FIELDS}
`;

export const UPDATE_TASK_STATUS_BY_ID: TypedDocumentNode<{
  update_taskStatuses_by_pk: typeOrStatus;
}> = gql`
  ${TASK_STATUS_FIELDS}
  mutation UPDATE_TASK_STATUS_BY_ID($id: Int!, $set: taskStatuses_set_input!) {
    update_taskStatuses_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...TaskStatusFields
    }
  }
`;

export const UPDATE_TASK_STATUSES_MANY = gql`
  ${TASK_STATUS_FIELDS}
  mutation UPDATE_TASK_STATUSES_MANY($updates: [taskStatuses_updates!]!) {
    update_taskStatuses_many(updates: $updates) {
      returning {
        ...TaskStatusFields
      }
    }
  }
`;

export const ADD_TASK_STATUS = gql`
  ${TASK_STATUS_FIELDS}
  mutation ADD_TASK_STATUS($object: taskStatuses_insert_input!) {
    insert_taskStatuses_one(object: $object) {
      ...TaskStatusFields
    }
  }
`;

export const DELETE_TASK_STATUS = gql`
  mutation DELETE_TASK_STATUS($id: Int!) {
    delete_taskStatuses_by_pk(id: $id) {
      id
    }
  }
`;

export const UPDATE_TASK_TYPE_BY_ID: TypedDocumentNode<{
  update_taskTypes_by_pk: typeOrStatus;
}> = gql`
  ${TASK_TYPE_FIELDS}
  mutation UPDATE_TASK_TYPE_BY_ID($id: Int!, $set: taskTypes_set_input!) {
    update_taskTypes_by_pk(pk_columns: { id: $id }, _set: $set) {
      ...TaskTypeFields
    }
  }
`;

export const UPDATE_TASK_TYPES_MANY = gql`
  ${TASK_TYPE_FIELDS}
  mutation UPDATE_TASK_TYPES_MANY($updates: [taskTypes_updates!]!) {
    update_taskTypes_many(updates: $updates) {
      returning {
        ...TaskTypeFields
      }
    }
  }
`;

export const ADD_TASK_TYPE = gql`
  ${TASK_TYPE_FIELDS}
  mutation ADD_TASK_TYPE($object: taskTypes_insert_input!) {
    insert_taskTypes_one(object: $object) {
      ...TaskTypeFields
    }
  }
`;

export const DELETE_TASK_TYPE = gql`
  mutation DELETE_TASK_TYPE($id: Int!) {
    delete_taskTypes_by_pk(id: $id) {
      id
    }
  }
`;
